import React from "react";
import {
  TableData,
  TableDataAction,
  TableDataColumn,
  TableDataRow,
} from "./types";
import Action from "./Action";

const TableBody = ({
  tableData,
  onRowClicked,
  onActionClicked,
  onRowSelected,
}: {
  tableData: TableData;
  onRowClicked?: (row: TableDataRow) => void;
  onActionClicked?: (action: TableDataAction, row: TableDataRow) => void;
  onRowSelected?: (row: TableDataRow) => void;
}) => {
  return (
    <tbody>
      {tableData.rows.map((row: TableDataRow, index: number) => (
        <tr key={index} role={onRowClicked ? "button" : ""}>
          <td className="align-middle">
            <div className="d-flex justify-content-between align-items-center">
              <input
                type="checkbox"
                checked={tableData.selectedRows?.includes(row)}
                onChange={(e) => {
                  onRowSelected?.(row);
                }}
              />
            </div>
          </td>
          {tableData.columns.map(
            (column: TableDataColumn) =>
              !column.hidden && (
                <td
                  key={`${index}-${column.id}`}
                  className="align-middle"
                  onClick={(e) => {
                    onRowClicked?.(row);
                  }}
                >
                  {row.cells[column.id]}
                </td>
              )
          )}
          {tableData.actions && (
            <td className="d-flex gap-1 align-middle">
              {tableData.actions.map((action, index) => (
                <Action
                  key={index}
                  action={action}
                  onClick={(e) => {
                    onActionClicked?.(action, row);
                  }}
                />
              ))}
            </td>
          )}
        </tr>
      ))}
    </tbody>
  );
};

export default TableBody;
