import React, { useState } from "react";
import useTranslate from "../../hooks/useTranslate";
import { TableDataRow } from "../Table/types";
import Input from "../ui/Input/Input";
import Textarea from "../ui/Textarea/Textarea";
import Select from "../ui/Select/Select";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { updateTableData } from "../../store/measurements/measurementsThunks";
import { createMeasurement, updateMeasurement } from "../../utils/api";
import ModalForm from "../ui/Modal/ModalForm";

const EditMeasurement = ({
  data,
  onClose,
}: {
  data: TableDataRow | null;
  onClose: (result: boolean) => void;
}) => {
  const tr = useTranslate();
  const dispatch: AppDispatch = useDispatch();

  const { variety, types } = useSelector(
    (state: RootState) => state.measurements.options
  );

  /*
    ● Año (número)
    ● Variedad (desplegable)
    ● Tipo (desplegable)
    ● color: string,
    ● temperatura: número
    ● graduación: número
    ● ph: número
    ● observaciones: string
    */
  const cells = data?.cells;
  const [measurement, setMeasurement] = useState<any>({
    id: cells?.id,
    year: cells?.year,
    variety: cells?.variety || variety?.[0]?.value,
    type: cells?.type || types?.[0]?.value,
    color: cells?.color,
    temperature: cells?.temperature,
    graduation: cells?.graduation,
    ph: cells?.ph,
    observations: cells?.observations,
  });
  // Modify a property of the measurement object
  const handleChange = (e: any) => {
    setMeasurement({
      ...measurement,
      [e.target.name]: e.target.value,
    });
  };
  // Check if we are editing or creating a new measurement
  const editMode = data !== null;
  const handleClose = (result: boolean) => {
    if (result) { // If the user clicked on the OK button
      if (editMode) { // If we are editing an existing measurement
        updateMeasurement(measurement)
          .then((response: any) => {
            dispatch(updateTableData());
            onClose(result);
          })
          .catch((error: any) => {
            console.log(error);
            alert(tr("ieUpdateMeasurementError"));
          });
      } else { // If we are creating a new measurement
        createMeasurement(measurement)
          .then((response: any) => {
            dispatch(updateTableData());
            onClose(result);
          })
          .catch((error: any) => {
            console.log(error);
            alert(tr("ieCreateMeasurementError"));
          });
      }
    } else {
      onClose(result); // If the user clicked on the Cancel button
    }
  };

  const title = editMode ? tr("ieEditMeasurement") : tr("ieAddMeasurement");
  return (
    <ModalForm
      title={title}
      onClose={handleClose}
      labelConfirm="ieSave"
      labelCancel="ieCancel"
    >
      <div className="px-4">
        {measurement.id && <div className="mb-3 text-center text-secondary"> {tr("ieId")}: {measurement.id}</div>}
        <Input
          type="number"
          label={tr("ieYear")}
          value={measurement.year}
          name="year"
          onChange={handleChange}
          required
        />
        <Select
          label={tr("ieVariety")}
          options={variety}
          value={measurement.variety}
          name="variety"
          onChange={handleChange}
          required
        />
        <Select
          label={tr("ieType")}
          options={types}
          value={measurement.type}
          name="type"
          onChange={handleChange}
          required
        />
        <Input
          type="text"
          label={tr("ieColor")}
          value={measurement.color}
          name="color"
          onChange={handleChange}
          required
        />
        <Input
          type="number"
          label={tr("ieTemperature")}
          value={measurement.temperature}
          name="temperature"
          onChange={handleChange}
          required
        />
        <Input
          type="number"
          label={tr("ieGraduation")}
          value={measurement.graduation}
          name="graduation"
          onChange={handleChange}
          required
        />
        <Input
          type="number"
          label={tr("iePh")}
          value={measurement.ph}
          name="ph"
          onChange={handleChange}
          required
        />
        <Textarea
          label={tr("ieObservations")}
          rows={7}
          value={measurement.observations}
          name="observations"
          onChange={handleChange}
          required
        />
      </div>
    </ModalForm>
  );
};

export default EditMeasurement;
