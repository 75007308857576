import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { languages } from '../../hooks/useTranslate';

export interface Language {
  id: string;
  label: string;
};

export interface VarsState {
  languages: Language[];
  language: string;
  defaultLanguage: string;
}

// Initial state
const initialState: VarsState = {
    languages: languages,
    language: 'EN',
    defaultLanguage: 'EN'
};

const varsSlice = createSlice({
  name: 'measurements',
  initialState,
  reducers: {
    setLanguage: (state: VarsState, { payload }: PayloadAction<string>) => {
      state.language = payload;
    },
  }
});

export const { setLanguage } = varsSlice.actions;

export default varsSlice.reducer;