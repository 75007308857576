import { configureStore } from '@reduxjs/toolkit';
import varsReducer from './vars';
import authReducer from './auth';
import measurementsReducer from './measurements'; 

const store = configureStore({
  reducer: {
    vars: varsReducer,
    auth: authReducer,
    measurements: measurementsReducer,
  }
});

export const dispatch = store.dispatch;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;