import React from "react";
import { TableData, TableDataColumn } from "./types";
import useTranslate from "../../hooks/useTranslate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortUp, faSortDown } from "@fortawesome/free-solid-svg-icons";

import styles from "./TableHead.module.css";

const TableHead = ({
  tableData,
  onColumnClicked,
  onSelectAllRows,
}: {
  tableData: TableData;
  onColumnClicked?: (column: TableDataColumn) => void;
  onSelectAllRows?: () => void;
}) => {
  const tr = useTranslate();

  const renderColumn = (column: TableDataColumn) => {
    if (column.sortable) {
      const isSelected = tableData.sort.column === column.id;
      const colorTop =
        isSelected && tableData.sort.direction === "ASC"
          ? "black"
          : "lightgray";
      const colorBottom =
        isSelected && tableData.sort.direction === "DESC"
          ? "black"
          : "lightgray";
      return (
        <div
          className="d-flex justify-content-between align-items-center gap-1 py-2"
          role="button"
          onClick={(e) => onColumnClicked?.(column)}
        >
          <span>{tr(column.label)}</span>
          <div className="d-flex flex-column">
            <FontAwesomeIcon
              icon={faSortUp}
              color={colorTop}
              className={styles["sort-icon-top"]}
            />
            <FontAwesomeIcon
              icon={faSortDown}
              color={colorBottom}
              className={styles["sort-icon-bottom"]}
            />
          </div>
        </div>
      );
    } else {
      return tr(column.label);
    }
  };
  const { rows, selectedRows } = tableData || {};
  const checked = selectedRows?.length > 0 && rows?.length === selectedRows?.length;
  return (
    <thead className="thead-dark">
      <tr>
        <th scope="col">
          <div className="py-2">
            <input
              type="checkbox"
              onChange={onSelectAllRows}
              checked={checked}
            />
          </div>
        </th>
        {tableData.columns.map(
          (column: TableDataColumn) =>
            !column.hidden && (
              <th key={column.id} scope="col">
                {renderColumn(column)}
              </th>
            )
        )}
        {tableData.actions && (
          <th scope="col">
            <div className="d-flex justify-content-between align-items-center gap-1 py-2">
              <span>{tr("ieActions")}</span>
            </div>
          </th>
        )}
      </tr>
    </thead>
  );
};

export default TableHead;
