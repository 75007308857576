import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import useTranslate from "../../hooks/useTranslate";

const Languages = ({ onClick }: { onClick: (id: string) => void }) => {
  const { languages, language } = useSelector((state: RootState) => state.vars);
  const tr = useTranslate();
  const getComputedStyle = (lang: any) => {
    return {
      backgroundColor: lang.id === language ? "#EEE" : undefined,
    };
  };
  return (
    <ul className="dropdown-menu">
      {languages.map((language) => (
        <li key={language.id}>
          <a
            className="dropdown-item"
            href="#"
            onClick={(e) => onClick(language.id)}
            style={getComputedStyle(language)}
          >
            {tr(language.label)}
          </a>
        </li>
      ))}
    </ul>
  );
};

export default Languages;
