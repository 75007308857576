import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PaginationData, SelectOptions, TableData, TableDataRow } from '../../components/Table/types';
import { getMeasurementActions, getMeasurementColumns } from '../../components/Measurements/utils';
import { updateTableData } from './measurementsThunks';

export interface MeasurementsState {
  loading: boolean;
  tableData: TableData;
  paginationData: PaginationData;
  options: SelectOptions;
}

// Initial state
const initialState: MeasurementsState = {
  loading: false,
  tableData: {
    sort: {
      column: 'id',
      direction: 'DESC',
    },
    columns: getMeasurementColumns(),
    actions: getMeasurementActions(),
    rows: [],
    selectedRows: [],
  },
  paginationData: {
    page: 1,
    limit: 10,
    total: 0
  },
  options: {
    types: [
      { value: 'tinto', label: 'ieTinto' },
      { value: 'blanco', label: 'ieBlanco' },
      { value: 'rosado', label: 'ieRosado' },
      { value: 'espumoso', label: 'ieEspumoso' },
      { value: 'dulce', label: 'ieDulce' },
      { value: 'fortificado', label: 'ieFortificado' },
    ],
  
    variety: [
      { value: 'cabernet_sauvignon', label: 'Cabernet Sauvignon' },
      { value: 'merlot', label: 'Merlot' },
      { value: 'syrah', label: 'Syrah' },
      { value: 'malbec', label: 'Malbec' },
      { value: 'pinot_noir', label: 'Pinot Noir' },
      { value: 'chardonnay', label: 'Chardonnay' },
      { value: 'sauvignon_blanc', label: 'Sauvignon Blanc' },
      { value: 'riesling', label: 'Riesling' },
      { value: 'moscatel', label: 'Moscatel' },
      { value: 'tempranillo', label: 'Tempranillo' },
    ]
  },
};

const measurementsSlice = createSlice({
  name: 'measurements',
  initialState,
  reducers: {
    setLoading: (state: MeasurementsState, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
    setPaginationData: (state: MeasurementsState, { payload }: PayloadAction<PaginationData>) => {
      state.paginationData = payload;
    },
    setSortData: (state: MeasurementsState, { payload }: PayloadAction<{ column: string; direction: 'ASC' | 'DESC' }>) => {
      state.tableData.sort = payload;
    },
    setSelectedRows: (state: MeasurementsState, { payload }: PayloadAction<TableDataRow[]>) => {
      state.tableData.selectedRows = payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateTableData.fulfilled, (state, { payload }: any) => {
        state.tableData.rows = payload.rows;
        state.tableData.selectedRows = [];
        state.paginationData.total = payload.total;
      })
  }
});

export const { setLoading, setPaginationData, setSortData, setSelectedRows } = measurementsSlice.actions;

export default measurementsSlice.reducer;