import React, { useEffect } from "react";

import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Home from "./components/pages/Home";
import Register from "./components/pages/Register";
import Layout from "./components/pages/Layout/Layout";
import Login from "./components/pages/Login/Login";

import { useSelector, useDispatch } from "react-redux";
import { RootState } from "./store";
import { setAuth } from "./store/auth/authSlice";

const App: React.FC = () => {
  const dispatch = useDispatch();
  const { isLoggedIn } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    // Check if has credentials in localStorage (remember me)
    const username = localStorage.getItem("username");
    const authToken = localStorage.getItem("authToken");
    if (username && authToken) {
      dispatch(setAuth({ username, authToken }));
    }
  }, [dispatch]);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route
            path="/login"
            element={isLoggedIn ? <Navigate to="/" /> : <Login />}
          />
          <Route
            path="/register"
            element={isLoggedIn ? <Navigate to="/" /> : <Register />}
          />
          <Route
            path="/"
            element={!isLoggedIn ? <Navigate to="/login" /> : <Home />}
          />
        </Route>
      </Routes>
    </Router>
  );
};

export default App;
