import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { getMeasurements } from '../../utils/api';
import { TableDataRow } from '../../components/Table/types';
import { setLoading } from './measurementsSlice';

const updateTableData = createAsyncThunk(
    'measurements/updateTableData',
    async (_arg, { getState, dispatch, rejectWithValue }) => {
        dispatch(setLoading(true));
        try {
            const state = (getState() as RootState).measurements;
            const { page, limit } = state.paginationData;
            const { column, direction } = state.tableData.sort;

            const response = await getMeasurements({ page, limit, column, direction });
            if (response?.success) {

                const total: number = response.data.count;
                const rows: TableDataRow[] = [];
                response.data?.rows.forEach((row: any) => {
                    rows.push({
                        cells: { ...row }
                    })
                });
                
                return { rows, total };
            }
            throw new Error(response?.message || "Unknown error");
        } catch(e) {
            console.error(e);
            return rejectWithValue(e); 
        } finally {
            dispatch(setLoading(false));
        }
    }
);

export { updateTableData };