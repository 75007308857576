import React from "react";
import { PaginationData } from "./types";
import useTranslate from "../../hooks/useTranslate";
import { formatTranslation } from "../../utils";

const Pagination = ({
  paginationData,
  onPageChanged,
  onLimitChanged,
}: {
  paginationData: PaginationData;
  onPageChanged: (page: number) => void;
  onLimitChanged: (limit: number) => void;
}) => {
  const tr = useTranslate();

  const maxPages = Math.ceil(paginationData.total / paginationData.limit);
  const handlePageChange = (page: number) => {
    if (page < 1 || page > maxPages) {
      return;
    }
    onPageChanged(page);
  };

  const minRow = (paginationData.page - 1) * paginationData.limit + 1;
  const maxRow = Math.min(
    paginationData.page * paginationData.limit,
    paginationData.total
  );
  const disableDecrement = paginationData.page <= 1;
  const disableIncrement = paginationData.page >= maxPages;
  return (
    <div className="d-flex flex-column flex-md-row align-items-center text-center pb-1 pt-2 px-2 border-top">
      {/* Pagination info */}
      <div className="flex-md-grow-0 bd-highlight d-flex align-items-center">
        <span className="fs-6">
          {formatTranslation(tr("iePaginationInfo"), {
            minRow,
            maxRow,
            total: paginationData.total,
          })}
        </span>
      </div>
      {/* Rows per page */}
      <div className="flex-grow-1 bd-highlight d-none d-sm-none d-md-none d-xl-block">
        <span className="me-2">{tr("ieRows")}</span>
        <input
          type="number"
          className="form-control"
          style={{ width: "60px", display: "inline" }}
          min={5}
          max={20}
          value={paginationData.limit}
          onChange={(e) => onLimitChanged(Number(e.target.value))}
        />
      </div>
      {/* Navigation buttons */}
      <div
        className="btn-group bd-highlight ms-md-auto py-1"
        role="group"
        aria-label="First group"
      >
        <button
          type="button"
          className="btn btn-outline-secondary"
          onClick={(e) => handlePageChange(1)}
          disabled={disableDecrement}
        >
          <span aria-hidden="true">&laquo;</span>
        </button>
        <button
          type="button"
          className="btn btn-outline-secondary"
          onClick={(e) => handlePageChange(paginationData.page - 1)}
          disabled={disableDecrement}
        >
          {"<"}
        </button>
        <input
          type="text"
          className="text-center"
          style={{ maxWidth: "125px" }}
          value={paginationData.page}
          onChange={(e) => handlePageChange(Number(e.target.value))}
        />
        <button
          type="button"
          className="btn btn-outline-secondary"
          onClick={(e) => handlePageChange(paginationData.page + 1)}
          disabled={disableIncrement}
        >
          {">"}
        </button>
        <button
          type="button"
          className="btn btn-outline-secondary"
          onClick={(e) => handlePageChange(maxPages)}
          disabled={disableIncrement}
        >
          <span aria-hidden="true">&raquo;</span>
        </button>
      </div>
    </div>
  );
};

export default Pagination;
