import React from "react";
import { rndId } from "../../../utils";
import useTranslate from "../../../hooks/useTranslate";

interface SelectProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
  id?: string;
  label: string;
  value: any;
  options: { value: any; label: string }[];
}

const Select = ({
  id = `id-${rndId()}`,
  label,
  value = "",
  options,
  ...props
}: SelectProps) => {
  const tr = useTranslate();
  return (
    <div className="form-floating mb-3">
      <select className="form-control" id={id} value={value} {...props}>
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {tr(option.label)}
          </option>
        ))}
      </select>
      <label htmlFor={id}>{label}</label>
    </div>
  );
};

export default Select;
