import React, { useEffect } from "react";
import Input from "../../ui/Input";
import Checkbox from "../../ui/Checkbox";
import Button from "../../ui/Button";
import { useDispatch } from "react-redux";
import { loginAsync } from "../../../store/auth/authThunks";
import { AppDispatch } from "../../../store";
import { Link } from "react-router-dom";
import useTranslate from "../../../hooks/useTranslate";

const Login = () => {
  const dispatch: AppDispatch = useDispatch();
  const tr = useTranslate();

  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [remember, setRemember] = React.useState(false);
  const [error, setError] = React.useState("");

  useEffect(() => {
    if (error) {
      const timeout = setTimeout(() => {
        setError("");
      }, 5000);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [error]);

  useEffect(() => {
    setRemember(localStorage.getItem("remember") === "true");
  }, []);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!username || !password) {
      return;
    }
    // Do login
    dispatch(loginAsync({ username, password, remember })).then((e: any) => {
      if (e.error) {
        const data = e.payload?.response?.data;
        const message = `${tr("ieErrorLoggingUser")}: ${
          data?.message || e.error?.message || "Unknown error"
        }`;
        setError(message);
      }
    });
  };

  return (
    <div className="container py-5">
      <div className="row d-flex justify-content-center align-items-center h-100">
        <div className="col-12 col-md-8 col-lg-6 col-xl-5">
          <div className="card shadow-2-strong rounded-2">
            <h2 className="text-center pt-5 m-0">
              <strong>{tr("ieLogin")}</strong>
            </h2>
            <form className="card-body px-5 pb-5 pt-4" onSubmit={handleSubmit}>
              <div className="my-4">
                <Input
                  type="text"
                  id="username"
                  name="username"
                  label={tr("ieUsername")}
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  required={true}
                />

                <Input
                  type="password"
                  id="password"
                  name="password"
                  label={tr("iePassword")}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required={true}
                />

                <Checkbox
                  label={tr("ieRememberMe")}
                  checked={remember}
                  onChange={(e) => setRemember(!remember)}
                />
              </div>

              <div className="form-text text-center">
                <Button type="submit" label={tr("ieLogin")} />
              </div>

              {error && (
                <div className="alert alert-danger mt-3 text-center" role="alert">
                  {error}
                </div>
              )}

              <div className="form-text text-center mt-4">
                <span>{tr("ieDontHaveAccount")} </span>
                <Link className="link-secondary" to="/register">
                  {tr("ieRegister")}
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
