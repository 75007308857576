
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { loginAsync, logoutAsync, registerAsync } from './authThunks';

interface AuthState {
  username: string | null;
  authToken: string | null;
  isLoggedIn: boolean;
}

// Initial state
const initialState: AuthState = {
  username: null,
  authToken: null,
  isLoggedIn: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuth: (state: AuthState, { payload }: PayloadAction<{ username: string; authToken: string }>) => {
      state.username = payload.username;
      state.authToken = payload.authToken;
      state.isLoggedIn = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginAsync.fulfilled, (state, { payload }) => {
        state.username = payload.username;
        state.authToken = payload.authToken;
        state.isLoggedIn = true;
      })
      .addCase(logoutAsync.fulfilled, (state) => {
        state.username = null;
        state.authToken = null;
        state.isLoggedIn = false;
      }).addCase(registerAsync.fulfilled, (state, { payload }) => {
        state.username = payload.username;
        state.authToken = payload.authToken;
        state.isLoggedIn = true;
      });
  },
});

export const { setAuth } = authSlice.actions;

export default authSlice.reducer;