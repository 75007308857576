import React, { useEffect, useState } from "react";
import PaginatedTable from "../Table/PaginatedTable";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { AppDispatch } from "../../store/store";
import { updateTableData } from "../../store/measurements/measurementsThunks";
import {
  setPaginationData,
  setSelectedRows,
  setSortData,
} from "../../store/measurements/measurementsSlice";
import Spinner from "../ui/Spinner/Spinner";
import useTranslate from "../../hooks/useTranslate";
import { TableDataAction, TableDataColumn, TableDataRow } from "../Table/types";
import EditMeasurement from "./EditMeasurement";
import { deleteMeasurements } from "../../utils/api";

const Measurements = () => {
  const dispatch: AppDispatch = useDispatch();
  const tr = useTranslate();

  const [showCreate, setShowCreate] = useState(false);
  const [currentData, setCurrentData] = useState<TableDataRow | null>(null);

  const { tableData, paginationData, loading } = useSelector(
    (state: RootState) => state.measurements
  );

  const handleUpdateTableDataErrors = (e: any) => {
    if (e.error) {
      alert(tr("ieUpdateTableDataError"));
    }
  };

  useEffect(() => {
    // Retrieve data from API
    dispatch(updateTableData()).then(handleUpdateTableDataErrors);
  }, []);

  const handlePageChanged = (page: number) => {
    // Update pagination data
    dispatch(setPaginationData({ ...paginationData, page }));
    dispatch(updateTableData()).then(handleUpdateTableDataErrors);
  };

  const handleLimitChanged = (limit: number) => {
    const maxPages = Math.ceil(paginationData.total / limit);
    if (maxPages < paginationData.page) {
      // Update pagination data to last page
      dispatch(setPaginationData({ ...paginationData, page: maxPages, limit }));
    } else {
      // Update pagination data
      dispatch(setPaginationData({ ...paginationData, limit }));
    }
    dispatch(updateTableData()).then(handleUpdateTableDataErrors);
  };

  const handleColumnClicked = (column: TableDataColumn) => {
    const sortData = { ...tableData.sort };
    if (sortData.column === column.id) {
      sortData.direction = sortData.direction === "ASC" ? "DESC" : "ASC";
    } else {
      sortData.column = column.id as string;
    }
    // Update sort data
    dispatch(setSortData(sortData));
    dispatch(updateTableData()).then(handleUpdateTableDataErrors);
  };

  const handleActionClicked = (action: TableDataAction, row: TableDataRow) => {
    switch (action.id) {
      case "edit":
        handleRowClicked(row);
        break;
      case "delete":
        if (window.confirm(tr("ieDeleteConfirm"))) {
          deleteMeasurements([row.cells.id]).then(() => {
            dispatch(updateTableData()).then(handleUpdateTableDataErrors);
          });
        }
        break;
    }
  };

  const handleRowClicked = (row: any) => {
    setCurrentData(row);
    setShowCreate(true);
  };

  const handleCloseModal = () => {
    setShowCreate(false);
    setCurrentData(null);
  };

  const handleSelectAllRows = () => {
    if (tableData.selectedRows.length === tableData.rows.length) {
      dispatch(setSelectedRows([]));
      return;
    }
    dispatch(setSelectedRows([...tableData.rows]));
  };

  const handleRowSelected = (row: TableDataRow) => {
    const result = [...tableData.selectedRows];
    const index = result.findIndex(
      (item: TableDataRow) => item.cells.id === row.cells.id
    );
    if (index > -1) {
      result.splice(index, 1);
    } else {
      result.push(row);
    }
    dispatch(setSelectedRows(result));
  };

  const handleRemoveSelected = () => {
    if (window.confirm(tr("ieDeleteConfirm"))) {
      deleteMeasurements(
        tableData.selectedRows.map((row: TableDataRow) => row.cells.id)
      ).then(() => {
        dispatch(updateTableData()).then(handleUpdateTableDataErrors);
      });
    }
  };

  return (
    <div>
      {loading && <Spinner />}
      {showCreate && (
        <EditMeasurement data={currentData} onClose={handleCloseModal} />
      )}
      <div className="d-flex justify-content-between align-items-center my-4">
        <div></div>
        <h2 className="text-center">{tr("ieRegisters")}</h2>
        <div className="d-flex gap-2">
          <button
            disabled={tableData.selectedRows.length === 0}
            className="btn btn-danger"
            onClick={() => handleRemoveSelected()}
          >
            {tr("ieDelete")}
          </button>
          <button
            className="btn btn-success"
            onClick={() => setShowCreate(true)}
          >
            {tr("ieAdd")}
          </button>
        </div>
      </div>
      <PaginatedTable
        tableData={tableData}
        paginationData={paginationData}
        onPageChanged={handlePageChanged}
        onLimitChanged={handleLimitChanged}
        onColumnClicked={handleColumnClicked}
        onRowClicked={handleRowClicked}
        onActionClicked={handleActionClicked}
        onSelectAllRows={handleSelectAllRows}
        onRowSelected={handleRowSelected}
      />
    </div>
  );
};

export default Measurements;
