import { useSelector } from 'react-redux';
import { RootState } from '../store';

import dictionary from './dictionary.json';

export const languages = dictionary?.languages;

export default function useTranslate() {
    const { language, defaultLanguage } = useSelector((state: RootState) => state.vars);
    const data: any = dictionary;
    const translate = (key: string) => {
        const texts = data?.texts;
        return texts?.[key]?.[language?.toUpperCase()] || texts?.[defaultLanguage?.toUpperCase()] || key;
    }
    return translate;
}