import React from "react";
import Table, { TableParams } from "./Table";
import { PaginationData, TableData, TableDataColumn } from "./types";
import Pagination from "./Pagination";

interface PaginatedTableParams extends TableParams {
  paginationData: PaginationData;
  onPageChanged: (page: number) => void;
  onLimitChanged: (limit: number) => void;
}

const PaginatedTable = ({
  paginationData,
  onPageChanged,
  onLimitChanged,
  ...props
}: PaginatedTableParams) => {
  return (
    <div className="bg-light p-1" style={{ borderRadius: "5px" }}>
      <div style={{ minHeight: "400px", overflowX: "auto" }}>
        <Table
          {...props}
        />
      </div>
      <Pagination
        paginationData={paginationData}
        onPageChanged={onPageChanged}
        onLimitChanged={onLimitChanged}
      />
    </div>
  );
};

export default PaginatedTable;
