import React from "react";

export interface ModalProps extends React.HTMLAttributes<HTMLElement> {}

const Modal = ({ children }: ModalProps) => {
  return (
    <>
      <div className="modal-backdrop show"></div>
      <div className="modal d-block">
        <div className="modal-dialog modal-dialog-scrollable modal-lg">
          {children}
        </div>
      </div>
    </>
  );
};

export default Modal;
