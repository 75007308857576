import React from "react";
import TableHead from "./TableHead";
import TableBody from "./TableBody";
import {
  TableData,
  TableDataAction,
  TableDataColumn,
  TableDataRow,
} from "./types";

export interface TableParams extends React.HTMLAttributes<HTMLTableElement> {
  tableData: TableData;
  onColumnClicked?: (column: TableDataColumn) => void;
  onRowClicked?: (row: TableDataRow) => void;
  onActionClicked?: (action: TableDataAction, row: TableDataRow) => void;
  onSelectAllRows?: () => void;
  onRowSelected?: (row: TableDataRow) => void;
}

const Table = ({
  tableData,
  onColumnClicked,
  onRowClicked,
  onActionClicked,
  onSelectAllRows,
  onRowSelected,
}: TableParams) => {
  return (
    <table
      className={`table table-striped mb-0 ${
        onRowClicked ? "table-hover" : ""
      }`}
    >
      <TableHead 
      tableData={tableData} 
      onColumnClicked={onColumnClicked} 
      onSelectAllRows={onSelectAllRows}/>
      <TableBody
        tableData={tableData}
        onRowClicked={onRowClicked}
        onActionClicked={onActionClicked}
        onRowSelected={onRowSelected}
      />
    </table>
  );
};

export default Table;
