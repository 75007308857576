
import axios from 'axios';
import store from '../../store/store';

const API_URL = process.env.REACT_APP_API_URL; 

const instance = axios.create({
  baseURL: API_URL,
  timeout: 10000,
});

export const login = async (username: string, password: string) => {
  try {
    const response = await instance.post('/login', {
      username,
      password,
    });
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const register = async (username: string, password: string) => {
  try {
    const response = await instance.post('/register', {
      username,
      password,
    });
    return response?.data;
  } catch (error: any) {
    throw error;
  }
};

export interface Measurement {
  id?: number;
  year: number;
  variety: string;
  type: string;
  color: string;
  temperature: number;
  graduation: number;
  ph: number;
  observations: string;
}

export const getAuthorization = () => {
  const state = store.getState();
  return state.auth.authToken;
}

export const createMeasurement = async (measurement: Measurement) => {
  try {
    const response = await instance.post('/measurements', { measurement }, {
      headers: {
        authorization: getAuthorization()
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const updateMeasurement = async (measurement: Measurement) => {
  try {
    const response = await instance.put('/measurements', { measurement }, {
      headers: {
        authorization: getAuthorization()
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const deleteMeasurements = async (ids: number[]) => {
  try {
    const response = await instance.delete(`/measurements`, {
      data: { ids },
      headers: {
        authorization: getAuthorization()
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const getMeasurements = async (params?: { page: number; limit?: number; column?: string; direction?: string }) => {
  try {
    const response = await instance.get('/measurements', {
      params,
      headers: {
        authorization: getAuthorization()
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};