import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { AppDispatch, RootState } from "../../store";
import { logoutAsync } from "../../store/auth/authThunks";
import { setLanguage } from "../../store/vars/varsSlice";
import useTranslate from "../../hooks/useTranslate";
import { useEffect } from "react";
import Languages from "./Languages";

const Navbar = () => {
  const dispatch: AppDispatch = useDispatch();
  const { isLoggedIn } = useSelector((state: RootState) => state.auth);
  const { languages } = useSelector((state: RootState) => state.vars);

  const tr = useTranslate();

  useEffect(() => {
    const lang = localStorage.getItem("lang");
    if (lang) {
      dispatch(setLanguage(lang));
    }
  }, [dispatch]);

  const handleLogout = () => {
    dispatch(logoutAsync());
  };

  const handleLanguage = (lang: string) => {
    localStorage.setItem("lang", lang);
    dispatch(setLanguage(lang));
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light border-bottom">
      <div className="container">
        <Link className="navbar-brand" to="/">
          {tr("ieTitle")}
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavAltMarkup"
          aria-controls="navbarNavAltMarkup"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className="collapse navbar-collapse justify-content-end"
          id="navbarNavAltMarkup"
        >
          <ul className="navbar-nav mr-auto">
            <li>
              <div className="dropdown">
                <button
                  className="btn dropdown-toggle"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {tr("ieLanguage")}
                </button>
                <Languages onClick={handleLanguage} />
              </div>
            </li>
            {isLoggedIn && (
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="#"
                  tabIndex={-1}
                  onClick={handleLogout}
                >
                  {tr("ieLogout")}
                </a>
              </li>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
