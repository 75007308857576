import React from "react";
import Modal, { ModalProps } from "./Modal";
import useTranslate from "../../../hooks/useTranslate";

interface ModalFormProps extends ModalProps {
  title?: string;
  labelConfirm: string;
  labelCancel: string;
  onClose: (result: boolean) => void;
  children: React.ReactNode;
}

const ModalForm = ({
  title,
  labelConfirm = "ieOk",
  labelCancel = "ieCancel",
  children,
  onClose,
  ...props
}: ModalFormProps) => {
  const tr = useTranslate();
  const handleSubmit = (e: any) => {
    e.preventDefault();
    onClose(true);
  };
  return (
    <Modal {...props} >
      <form className="modal-content" onSubmit={handleSubmit}>
        {title && (
          <div className="modal-header border-bottom-0 d-flex justify-content-center pb-0">
            <h3 className="modal-title mb-0">{tr(title)}</h3>
          </div>
        )}
        <div className="modal-body">{children}</div>
        <div className="modal-footer border-top-0 pt-0">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={(e) => onClose(false)}
          >
            {tr(labelCancel)}
          </button>
          <button type="submit" className="btn btn-primary">
            {tr(labelConfirm)}
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default ModalForm;
