import { createAsyncThunk } from '@reduxjs/toolkit';
import { login, register } from '../../utils/api';

export const loginAsync = createAsyncThunk(
    'auth/login',
    async ({ username, password, remember }: { username: string, password: string, remember: boolean }, { rejectWithValue }) => {
        try {
            const response = await login(username, password);
            if (response?.success) {
                const authToken = `Bearer ${response.authToken}`;
                if (remember) {
                    localStorage.setItem('username', username);
                    localStorage.setItem('authToken', authToken);
                    localStorage.setItem('remember', 'true');
                } else {
                    localStorage.removeItem('remember');
                }
                return { username, authToken };
            }
            throw new Error(response?.message || "Unknown error");
        } catch (e) {
            console.error(e);
            return rejectWithValue(e);
        }
    }
);

export const logoutAsync = createAsyncThunk(
    'auth/logout',
    async (_arg, _void) => {
        localStorage.removeItem('username');
        localStorage.removeItem('authToken');
    }
);

export const registerAsync = createAsyncThunk(
    'auth/register',
    async ({ username, password }: { username: string, password: string }, { rejectWithValue }) => {
        try {
            const response = await register(username, password);
            if (response?.success) {
                const authToken = `Bearer ${response.authToken}`;
                localStorage.setItem('username', username);
                localStorage.setItem('authToken', authToken);
                localStorage.setItem('remember', 'true');
                return { username, authToken };
            }
            throw new Error(response?.message || "Unknown error");
        } catch (e) {
            console.error(e);
            return rejectWithValue(e);
        }
    }
);