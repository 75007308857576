import React, { useEffect } from "react";
import Input from "../../ui/Input/Input";
import Button from "../../ui/Button/Button";
import { AppDispatch } from "../../../store/store";
import { useDispatch } from "react-redux";
import { registerAsync } from "../../../store/auth/authThunks";
import { Link } from "react-router-dom";
import useTranslate from "../../../hooks/useTranslate";

const Register = () => {
  const dispatch: AppDispatch = useDispatch();
  const tr = useTranslate();

  const [username, setUsername] = React.useState("");
  const [password1, setPassword1] = React.useState("");
  const [password2, setPassword2] = React.useState("");
  const [error, setError] = React.useState("");

  useEffect(() => {
    if (error) {
      const timeout = setTimeout(() => {
        setError("");
      }, 5000);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [error]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!username || !password1 || !password2) {
      return;
    }

    if (password1 !== password2) {
      setError(tr("iePasswordsDontMatch"));
      return;
    }

    dispatch(registerAsync({ username, password: password1 })).then(
      (e: any) => {
        if (e.error) {
          const data = e.payload?.response?.data;
          const message = `${tr("ieErrorRegisteringUser")}: ${
            data?.message || e.error?.message || "Unknown error"
          }`;
          setError(message);
        }
      }
    );
  };

  return (
    <div className="container py-5">
      <div className="row d-flex justify-content-center align-items-center h-100">
        <div className="col-12 col-md-8 col-lg-6 col-xl-5">
          <div className="card shadow-2-strong rounded-2">
            <h2 className="text-center pt-5 m-0">
              <strong>{tr("ieUserRegistrationForm")}</strong>
            </h2>
            <form
              className="card-body px-5 pb-5 pt-4"
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              <div className="my-4">
                <Input
                  type="text"
                  id="username"
                  name="username"
                  label={tr("ieUsername")}
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  required={true}
                />

                <Input
                  type="password"
                  id="password1"
                  name="password1"
                  label={tr("ieChoosePassword")}
                  value={password1}
                  onChange={(e) => setPassword1(e.target.value)}
                  required={true}
                />

                <Input
                  type="password"
                  id="password2"
                  name="password2"
                  label={tr("ieConfirmPassword")}
                  value={password2}
                  onChange={(e) => setPassword2(e.target.value)}
                  required={true}
                />
              </div>

              <div className="form-text text-center">
                <Button type="submit" label={tr("ieRegister")} />
              </div>

              {error && (
                <div className="alert alert-danger mt-3 text-center" role="alert">
                  {error}
                </div>
              )}

              <div className="form-text text-center mt-4">
                <span>{tr("ieAlreadyHaveAccount")} </span>
                <Link className="link-secondary" to="/login">
                  {tr("ieLogin")}
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
