import React, { useEffect } from "react";
import styles from "./Spinner.module.css";

const Spinner = () => {
  const [visible, setVisible] = React.useState<boolean>(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setVisible(true);
    }, 500); // Delay to show spinner
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <>
      {visible && (
        <div className={styles["spinner-overlay"]}>
          <div
            className="spinner-border"
            style={{ width: "3rem", height: "3rem" }}
            role="status"
          ></div>
        </div>
      )}
    </>
  );
};

export default Spinner;
