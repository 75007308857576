import React from "react";
import { TableDataAction } from "./types";
import useTranslate from "../../hooks/useTranslate";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';

interface ActionProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  action: TableDataAction;
}

const Action = ({ action, ...props }: ActionProps) => {
  const tr = useTranslate();
  const label = action?.label ? tr(action.label) : "";
  switch (action.id) {
    case "delete":
      return (
        <button type="button" className="btn btn-danger btn-sm text-center" {...props}>
          <FontAwesomeIcon icon={faTrash} size="sm"/>
          {`${label}`}
        </button>
      );
    case "edit":
      return (
        <button type="button" className="btn btn-primary btn-sm text-center d-none d-md-block" {...props}>
          <FontAwesomeIcon icon={faEdit} size="sm" />
          {`${label}`}
        </button>
      );
  }
  return (
    <button type="button" {...props}>
      {label}
    </button>
  );
};

export default Action;
