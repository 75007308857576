import { TableDataAction } from "../../Table/types";

const getMeasurementActions = () => {
  const actions: TableDataAction[] = [
    {
      id: "edit",
    },
    {
      id: "delete",
    },
  ];

  return actions;
};

export default getMeasurementActions;
