import React from "react";
import { rndId } from "../../../utils";

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
    id?: string;
    label: string;
    type?: 'text' | 'password' | 'email' | 'number' | 'date' | 'time' | 'datetime-local';
}

const Input = ({
  id = `id-${rndId()}`,
  label,
  placeholder = label,
  value = "",
  ...props
}: InputProps) => {
  return (
    <div className="form-floating mb-3">
      <input
        className="form-control"
        placeholder={placeholder}
        id={id}
        value={value}
        {...props}
      />
      <label htmlFor={id}>{label}</label>
    </div>
  );
};

export default Input;
