import React from "react";
import { rndId } from "../../../utils";

interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  id?: string;
  label: string;
}

const Checkbox = ({ id = `id-${rndId()}`, label, ...props }: CheckboxProps) => {
  return (
    <div className="form-check mb-3">
      <input className="form-check-input" type="checkbox" id={id} {...props} />
      <label className="form-check-label" htmlFor={id}>
        {label}
      </label>
    </div>
  );
};

export default Checkbox;
