import { TableDataColumn } from "../../Table/types";

const getMeasurementColumns = (): TableDataColumn[] => {

    /*
    ● Año (número)
    ● Variedad (desplegable)
    ● Tipo (desplegable)
    ● color: string,
    ● temperatura: número
    ● graduación: número
    ● ph: número
    ● observaciones: string
    */

    return [
        {
            id: 'id',
            label: "ieId",
            sortable: true,
        },
        {
            id: 'year',
            label: "ieYear",
            sortable: true,
        },
        {
            id: 'variety',
            label: "ieVariety",
            sortable: true,
        },
        {
            id: 'type',
            label: "ieType",
            sortable: true,
        },
        {
            id: 'color',
            label: "ieColor",
            sortable: true,
        },
        {
            id: 'temperature',
            label: "ieTemperature",
            sortable: true,
        },
        {
            id: 'graduation',
            label: "ieGraduation",
            sortable: true,
        },
        {
            id: 'ph',
            label: "iePh",
            sortable: true,
        },
        {
            id: 'observations',
            label: "ieObservations",
            sortable: true,
        },
    ];

}

export default getMeasurementColumns;