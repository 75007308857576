import React from "react";
import { rndId } from "../../../utils";

interface TextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  id?: string;
  label: string;
}

const Textarea = ({
  id = `id-${rndId()}`,
  label,
  placeholder = label,
  value = "",
  ...props
}: TextareaProps) => {
  return (
    <div className="form-floating mb-3">
      <textarea
        className="form-control h-auto"
        placeholder={placeholder}
        id={id}
        value={value}
        {...props}
      />
      <label htmlFor={id}>{label}</label>
    </div>
  );
};

export default Textarea;
